@keyframes blink {
    0% {
        fill: darkred;
    }
    50% {
        fill: #111f64 !important;
    }
    100% {
        fill: darkred;
    }
}
.blinking {
    animation: blink 1s infinite;
    transition: fill 0.5s ease;
}

.clock{
    background-color: #111f64!important;
    color: white;
    padding: 5px;
    border-radius: 10px;
    border: 3px solid white;
}
