#loginBody{
    background-image: url(/static/media/123.7cea7d29.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100vh;
}
#rowLogin{
    height: 100vh;
}
#divCard{
    display: flex;
    align-items: center;
    margin-left: 800px;
}
#cardStyle{
    background-image: linear-gradient(60deg, #15063f 0%, #143c5e 50%, #2b3d42 65%, #05222f 100%);
    box-shadow: 0 0 5px 5px rgba(255, 255, 255, .3);
}
#inputTopText{
    color: white;
}
#submitButton{
    background-image: linear-gradient(120deg, #49638d 0%, #58cafa 100%);
    border: 0;
}
#submitButton:hover{
    box-shadow: 0 0 8px 8px rgba(255, 255, 255, .2);
}
body{
    font-family: monospace!important;
}

.trigger{
    margin-left: 15px;
    font-size: 30px;
}
.trigger:hover {
    color: #1890ff;
}

#logo {
    padding: 10px;
    background-color: darkseagreen;
    text-align: center;
}
.ql-toolbar {
    background-color: #f3f3f3;
    /* Additional toolbar styles */
}

/* Customize specific buttons or elements within the toolbar */
.ql-bold {
    /* Style for the 'bold' button */
}
@-webkit-keyframes blink {
    0% {
        fill: darkred;
    }
    50% {
        fill: #111f64 !important;
    }
    100% {
        fill: darkred;
    }
}
@keyframes blink {
    0% {
        fill: darkred;
    }
    50% {
        fill: #111f64 !important;
    }
    100% {
        fill: darkred;
    }
}
.blinking {
    -webkit-animation: blink 1s infinite;
            animation: blink 1s infinite;
    transition: fill 0.5s ease;
}

.clock{
    background-color: #111f64!important;
    color: white;
    padding: 5px;
    border-radius: 10px;
    border: 3px solid white;
}

.back{
    background: url(/static/media/Untiasedasdtled-1.da60c70b.png);
    background-size: cover;
    height: 100vh;
    padding: 24px;
}
.svg-map {
    width: 100%;
    height: auto;
    stroke: #ffffff;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.svg-map__location {
    fill: #111f65;
    cursor: pointer;
}
.svg-map__location:focus, .svg-map__location:hover {
    fill: #efd2d2;
    outline: 0;
}
.svg-map__location[aria-checked=true] {
    fill: #f4bc44;
}
.ant-layout-content{
    padding: 0!important;
}




